<template>
  <v-dialog v-model="opened" max-width="1200" persistent>
    <v-card>
      <v-card-title class="headline">
        Daftar Kendaraan
        <v-btn icon @click="close()" class="ml-auto">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <datatable
          ref="datatable"
          module="angkutan"
          :title="title"
          :url="url"
          :columns="columns"
          disable-create
          disable-action
          show-select
          v-model="selected"
          :filter="filter"
        >
          <template v-slot:action>
            <v-btn
              type="button"
              color="primary"
              depressed
              small
              class="ml-auto mt-5 mr-2"
              :loading="loading"
              :disabled="loading"
              @click.prevent="save()"
            >
              <v-icon small left>mdi-plus</v-icon>
              Tambah
            </v-btn>
          </template>
        </datatable>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import Datatable from "@/view/component/Datatable.vue";

export default {
  props: {
    onSave: {
      type: Function,
      default: function() {},
    },
  },
  data() {
    return {
      title: "Angkutan",
      url: "angkutan/data",
      selected: [],
      trayekId: null,
      opened: false,
      columns: [
        {
          value: "id",
        },
        {
          text: "No. Kendaraan",
          value: "no_kendaraan",
          sortable: true,
          searchable: true,
        },
        {
          text: "Perusahaan",
          value: "nama_pob",
          sortable: true,
          searchable: true,
        },
      ],
      filter: null,
    };
  },
  components: {
    Datatable,
  },
  methods: {
    open() {
      this.opened = true;
      //   this.filter = [
      //     { column: "trayek_id", value: [trayekId], operator: "notIn" },
      //   ];
      this.$refs.datatable.getData();
    },
    close() {
      this.opened = false;
      this.selected = [];
      this.filter = null;
    },
    save() {
      if (this.selected.length > 0) {
        let listId = [];
        this.selected.forEach((v) => listId.push(v.id));
        this.onSave(listId);
      } else {
        this.onSave(null);
      }
      this.selected = [];
      this.filter = null;
      this.close();
    },
  },
};
</script>
