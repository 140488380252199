<template>
  <div>
    <div class="card card-custom gutter-b">
      <!-- <div class="card-body">POB {{ value }} - {{ data }}</div> -->
      <v-btn
        color="primary"
        elevation="2"
        small
        class="ml-auto"
        type="button"
        @click="onClickCreate()"
      >
        Tambah {{ this.title }}
      </v-btn>

      <datatable
        ref="datatableAngkutan"
        module="angkutan"
        :title="title"
        :url="url"
        :columns="columns"
        disable-create
        disable-action
        :filter="filter"
      >
        <template v-slot:item.id="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <a
                href="#"
                class="btn btn-icon btn-light btn-xs mx-1"
                @click="onClickDelete(item)"
                v-on="on"
                v-bind="attrs"
              >
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    :src="asset('/media/svg/icons/General/Trash.svg')"
                  ></inline-svg>
                  <!--end::Svg Icon-->
                </span>
              </a>
            </template>
            <span>Hapus</span>
          </v-tooltip>
        </template>
      </datatable>
      <list-select-angkutan
        ref="selectangkutan"
        :on-save="onSave"
      ></list-select-angkutan>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import ListSelectAngkutan from "./ListSelectAngkutan.vue";
import { asset } from "@/core/utils/";
import Datatable from "@/view/component/Datatable.vue";

export default {
  created() {
    this.calculate();
  },
  props: {
    canEdit: {
      type: Boolean,
    },
    trayekId: {
      type: String,
    },
  },
  data() {
    return {
      data: [],
      title: "Kendaraan",
      url: "angkutan/data",
      loading: false,
      filter: [{ column: "trayek_id", value: this.trayekId, operator: "eq" }],
      columns: [
        {
          text: "No. Kendaraan",
          value: "no_kendaraan",
          sortable: true,
          searchable: true,
        },
        {
          text: "Perusahaan",
          value: "nama_pob",
          sortable: true,
          searchable: true,
        },
        {
          text: "Tindakan",
          value: "id",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    onClickDelete(val) {
      ApiService["update"]("angkutan?id=" + val.id, {
        trayek_id: null,
      }).then(() => {
        this.$refs.datatableAngkutan.getData();
      });
    },
    onSave(val) {
      if (val && val.length > 0) {
        ApiService["post"]("trayek/add-angkutan", {
          trayek_id: this.trayekId,
          angkutan: val,
        }).then(() => {
          this.$refs.datatableAngkutan.getData();
        });
        // if (!this.value) {
        //   this.value = [];
        // }
        // this.value = [...this.value, ...val];
        // this.$emit("input", this.value);
        // this.calculate();
      }
    },
    async calculate() {
      if (this.value == null || this.value.length <= 0) {
        this.data = [];
        return;
      }

      this.loading = true;
      const params = {
        filter: [
          {
            column: "id",
            operator: "in",
            value: this.value,
          },
        ],
        columns: [
          { data: "id", searchable: false },
          { data: "no_kendaraan", searchable: true },
          { data: "no_izin", searchable: true },
          { data: "alamat", searchable: true },
        ],
        perPage: 20,
      };

      const { data } = await ApiService.post("angkutan/data", params);
      this.data = data.data;
      this.loading = false;
    },
    onClickCreate() {
      this.$refs.selectangkutan.open();
    },
    asset,
  },
  watch: {
    value() {
      this.calculate();
    },
  },
  components: {
    ListSelectAngkutan,
    Datatable,
  },
};
</script>
